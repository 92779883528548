import React from "react"
import styled from "styled-components"
import Seo from "../components/seo"
import { motion } from "framer-motion"
import { motionItem } from "../utils/motion"
import uniqid from "uniqid"

import Breakpoints from "../utils/breakpoints"
import Reference from "../components/reference"
import Testimonials from "../components/testimonials"

import TillDesktop from "../images/mockups/julianberger_mockup_screen_till_ayasse_desktop.png"
import TillTablet from "../images/mockups/julianberger_mockup_screen_till_ayasse_tablet.png"
import EatDesktop from "../images/mockups/julianberger_mockup_screen_eat2day_desktop.png"
import EatTablet from "../images/mockups/julianberger_mockup_screen_eat2day_tablet.png"
import EatMobile from "../images/mockups/julianberger_mockup_screen_eat2day_mobile.png"
import GolfDesktop from "../images/mockups/julianberger_mockup_screen_justgolf_desktop.png"
import GolfTablet from "../images/mockups/julianberger_mockup_screen_justgolf_tablet.png"
import GolfMobile from "../images/mockups/julianberger_mockup_screen_justgolf_mobile.png"
import ArtDesktop from "../images/mockups/julianberger_mockup_screen_arthouse_desktop.png"
import ArtTablet from "../images/mockups/julianberger_mockup_screen_arthouse_tablet.png"
import ArtMobile from "../images/mockups/julianberger_mockup_screen_arthouse_mobile.png"
import KoKuMuDesktop from "../images/mockups/julianberger_mockup_screen_kokumu_desktop.png"
import KoKuMuTablet from "../images/mockups/julianberger_mockup_screen_kokumu_tablet.png"
import KoKuMuMobile from "../images/mockups/julianberger_mockup_screen_kokumu_mobile.png"
import MagnidDesktop from "../images/mockups/julianberger_mockup_screen_magnid-hub_desktop.png"
import MagnidTablet from "../images/mockups/julianberger_mockup_screen_magnid-hub_tablet.png"
import MagnidMobile from "../images/mockups/julianberger_mockup_screen_magnid-hub_mobile.png"
import JIBDesktop from "../images/mockups/julianberger_mockup_screen_jib_desktop.png"
import JIBTablet from "../images/mockups/julianberger_mockup_screen_jib_tablet.png"
import JIBMobile from "../images/mockups/julianberger_mockup_screen_jib_mobile.png"
import RanexaVideo from "../images/mockups/julianberger_mockup_screen_ranexa_animation_tablet_small.mp4"
import LeaderboardDesktop from "../images/mockups/julianberger_mockup_screen_leaderboard_desktop.png"
import LeaderboardTablet from "../images/mockups/julianberger_mockup_screen_leaderboard_tablet.png"
import LeaderboardMobile from "../images/mockups/julianberger_mockup_screen_leaderboard_mobile.png"
import CytocareDesktop from "../images/mockups/julianberger_mockup_screen_cytocare_desktop.png"
import CytocareTablet from "../images/mockups/julianberger_mockup_screen_cytocare_tablet.png"
import CytocareMobile from "../images/mockups/julianberger_mockup_screen_cytocare_mobile.png"

const references = [
  {
    srcDesktop: CytocareDesktop,
    srcTablet: CytocareTablet,
    srcMobile: CytocareMobile,
    bgColor: "#117680",
    headline: "Cyto-care.eu GmbH",
    subline: "Relaunch und individuelles Shop-System",
    text: (
      <p>
        Als Webdeveloper hatte ich die Gelegenheit, eine Website für einen
        Kunden aus dem medizinischen Bereich zu erstellen. Die Designvorlage
        wurde von einer Agentur bereitgestellt und ich habe die Designvorlage
        entsprechend den Kundenwünschen umgesetzt und das Shop-System angepasst.
        <br />
        <br />
        Die Zusammenarbeit mit dem Kunden war sehr produktiv und ich konnte
        meine Erfahrung im Webdesign einbringen, um eine benutzerfreundliche
        Navigation und ein ansprechendes Design zu schaffen. Der integrierte
        Shop mit Kalender-Tool erleichtert die Arbeitsabläufe und verbessert die
        interne Kommunikation im Unternehmen.
      </p>
    ),
    tech: ["wordpress", "woo", "js"],
  },
  {
    srcDesktop: TillDesktop,
    srcTablet: TillTablet,
    srcMobile: TillTablet,
    bgColor: "#131313",
    headline: "Till Ayasse",
    subline: "Künstler",
    text: (
      <p>
        Till, ein aufstrebender Künstler aus Wien, wollte seine Kunstwerke einer
        breiteren Öffentlichkeit zugänglich machen und entschied sich deshalb,
        mich mit der Erstellung seiner Website zu beauftragen.
        <br />
        <br />
        Um Till's Wünsche und Anforderungen zu verstehen, saßen wir zu Beginn
        des Projekts zusammen und besprachen seine bevorzugte Ästhetik, Farben
        und Layout-Optionen. Auf Basis dieser Informationen entwickelte ich eine
        Design-Strategie, die seine Kunstwerke optimal präsentieren würde.
        Nachdem wir uns auf ein Design geeinigt hatten, erstellte ich eine
        Vorlage und wir entwickelten gemeinsam die Inhalte, die auf der Website
        zu finden sein würden.
        <br />
        <br />
        Während der Erstellung der Website arbeiteten wir eng zusammen, um
        sicherzustellen, dass jede Seite von Till's Website seinen Vorstellungen
        entsprach. Ich integrierte eine Galerie-Funktion, um seine Kunstwerke
        ansprechend und leicht zugänglich zu präsentieren. Auch die
        Kontaktinformationen sollten leicht zu finden sein und Besucher können
        problemlos Kontakt mit Till aufnehmen. Am Ende war Till sehr zufrieden
        mit der Arbeit und konnte seine Kunstwerke einer breiteren
        Öffentlichkeit präsentieren.
      </p>
    ),
    tech: ["wordpress", "js"],
  },
  {
    srcDesktop: JIBDesktop,
    srcTablet: JIBTablet,
    srcMobile: JIBMobile,
    bgColor: "#38adae",
    headline: "Julian Ismael Berger",
    subline: "Relaunch",
    text: (
      <p>
        Ich habe vor kurzem meine neue Homepage mit Hilfe eines modernen
        Technologie-Stacks (Javascript, React und GatsbyJS) erstellt und bin mit
        dem Ergebnis sehr zufrieden. Der Einsatz dieser Technologien hat es mir
        ermöglicht, eine äußerst schnelle, interaktive und benutzerfreundliche
        Website zu entwickeln.
        <br />
        <br />
        Dank React konnte ich meine Website in komponentenbasierte Elemente
        aufteilen, was die Wiederverwendbarkeit und Wartbarkeit des Codes
        erleichtert hat. GatsbyJS hat mir geholfen, eine optimierte statische
        Seite zu erstellen, die sowohl für Suchmaschinen als auch für Nutzer
        schnell geladen wird. Zusammen mit der Verwendung von Javascript konnte
        ich eine reibungslose Benutzererfahrung schaffen und meine Website mit
        dynamischen Inhalten füllen.
        <br />
        <br />
        Alles in allem hat mich diese Erfahrung ermutigt, moderne
        Technologie-Stacks für zukünftige Projekte zu nutzen bzw. diese dem
        Kunden nahe zu legen.
      </p>
    ),
    tech: ["js", "react", "gatsby"],
  },
  {
    srcDesktop: MagnidDesktop,
    srcTablet: MagnidTablet,
    srcMobile: MagnidMobile,
    bgColor: "#F70041",
    headline: "magnid Hub",
    subline: "Kundenportal",
    text: (
      <p>
        Ich hatte das Vergnügen, für einen Kunden eine Webseite zu entwickeln,
        die verschiedene Inhalte und Funktionen wie eine Video- und
        Content-Plattform beinhaltet. Der Kunde hatte bereits ein Design
        vorgegeben und es war meine Aufgabe, die Webseite entsprechend
        umzusetzen und die verschiedenen Funktionen zu integrieren.
        <br />
        <br />
        Die Seite bietet den Nutzern die Möglichkeit, verschiedene Inhalte
        herunterzuladen und Informationen zu einem Service zu bekommen, den sie
        gebucht haben. Der Kunde hob hervor, dass die Umsetzung der Seite
        professionell und schnell erfolgte und dass die Benutzerfreundlichkeit
        und das Design der Seite hervorragend waren. Ich freue mich, dass ich
        dazu beitragen konnte, die Vision des Kunden umzusetzen und eine
        Webseite zu erstellen, die den Anforderungen und Bedürfnissen des Kunden
        entspricht.
      </p>
    ),
    tech: ["wordpress", "js"],
  },
  {
    srcDesktop: EatDesktop,
    srcTablet: EatTablet,
    srcMobile: EatMobile,
    bgColor: "#92b437",
    headline: "Eat2Day",
    subline: "Ernährungsberatung",
    text: (
      <p>
        Für den Relaunch des Internetauftritts der Ernährungsberaterin Mag.
        Veronika Macek-Strokosch habe ich ein umfassendes Konzept entwickelt.
        Basierend auf einem Design-Mockup habe ich persönlich alle gewünschten
        Funktionen und eine individuelle Darstellung in einem eigens angepassten
        Wordpress-Theme umgesetzt.
        <br />
        <br />
        Dabei lag mein besonderes Augenmerk auf den individuellen Anforderungen
        und Bedürfnissen der Kundin. So konnte ich einen einzigartigen und
        ansprechenden Online-Auftritt schaffen, der die Expertise und
        Professionalität der Ernährungsberaterin optimal präsentiert.
        <br />
        <br />
        Durch meine gezielte Umsetzung des Konzepts konnte eine zeitgemäße und
        benutzerfreundliche Website entstehen. Diese bietet nicht nur
        informative Inhalte, sondern auch inspirierende Elemente, welche einen
        hohen Mehrwert für die Zielgruppe der Kundin darstellen.
      </p>
    ),
    tech: ["wordpress", "js"],
  },
  {
    srcDesktop: LeaderboardDesktop,
    srcTablet: LeaderboardTablet,
    srcMobile: LeaderboardMobile,
    bgColor: "#000000",
    headline: "Leaderboard",
    subline: "SAAS",
    text: (
      <p>
        Ich habe eine Webseite für einen Kunden erstellt, die ein Leaderboard
        enthält, das die Daten verschiedener Drittanbieter-APIs sammelt und
        verarbeitet. Die Webseite beinhaltet zudem eine Verwaltungsoberfläche,
        die es dem Kunden ermöglicht, die Daten auf einfache und intuitive Weise
        zu verwalten und zu aktualisieren.
        <br />
        <br />
        Das Leaderboard-System ist äußerst flexibel und kann für verschiedene
        Zwecke und Anwendungen wiederverwendet werden. Der Kunde war mit dem
        Ergebnis sehr zufrieden und betonte die hohe Qualität der Umsetzung
        sowie die schnelle und professionelle Abwicklung des Projekts. Ich bin
        froh, dass ich dazu beitragen konnte, eine Webseite zu erstellen, die
        den Anforderungen und Bedürfnissen des Kunden entspricht und dass das
        System nun für zukünftige Projekte wiederverwendet werden kann.
      </p>
    ),
    tech: ["php", "js"],
  },
  {
    srcDesktop: ArtDesktop,
    srcTablet: ArtTablet,
    srcMobile: ArtMobile,
    bgColor: "#D31616",
    headline: "Art House Project",
    subline: "Plattform für Kusnt und Künstler",
    text: (
      <p>
        Der Kunde war von der professionellen und schnellen Umsetzung der
        Webseite sowie von deren hervorragendem Design und der
        Benutzerfreundlichkeit begeistert. Er zeigte sich insgesamt äußerst
        zufrieden mit dem Ergebnis meiner Arbeit für das Arthouse-Project.
        <br />
        <br />
        Als Plattform für Kunst und Künstler unterstützt das Arthouse-Project
        talentierte Künstler und veranstaltet Kunstmessen und Ausstellungen. Die
        neue Webseite hat dem Unternehmen geholfen, seine Reichweite zu erhöhen
        und sein Engagement für die Kunstszene noch besser zu kommunizieren. Ich
        bin stolz darauf, einen Beitrag zu dieser Mission geleistet zu haben und
        hoffe, dass die Webseite dem Arthouse-Project dabei hilft, weiterhin
        erfolgreich zu sein und noch mehr Künstler und Kunstinteressierte zu
        erreichen.
      </p>
    ),
    tech: ["wordpress", "js"],
  },
  {
    srcDesktop: GolfDesktop,
    srcTablet: GolfTablet,
    srcMobile: GolfMobile,
    bgColor: "#76b729",
    headline: "Justgolf",
    subline: "Reiseportal",
    text: (
      <p>
        Im Rahmen einer Zusammenarbeit mit einer Agentur Wundermild wurde für
        das Tochterunternehmen "JUST GOLF" der Pegasus Incoming GmbH eine
        Onlinepräsenz zur Vermittlung von Golfreisen geschaffen. Hierbei lag der
        Fokus auf einer übersichtlichen und benutzerfreundlichen Darstellung
        sowie einer redaktionellen Verwaltung der Reiseinhalte.
        <br />
        <br />
        Die Webseite zeichnet sich durch eine präzise und einfach zu bedienende
        Suchfunktion aus. Zusätzlich ermöglicht eine individualisierte
        Darstellung und Verwaltung der Reisen eine maßgeschneiderte Ansprache
        der Zielgruppe. Durch diese umfassende Gestaltung kann das
        Golfreiseportal einen hohen Mehrwert für Interessenten bieten und
        zugleich einen effektiven Beitrag zur Wachstumsstrategie von "JUST GOLF"
        leisten.
      </p>
    ),
    tech: ["wordpress", "js"],
  },
  {
    srcDesktop: KoKuMuDesktop,
    srcTablet: KoKuMuTablet,
    srcMobile: KoKuMuMobile,
    bgColor: "#e72512",
    headline: "Koch Kultur Museum",
    subline: "Kochportal",
    text: (
      <p>
        Ich hatte die großartige Möglichkeit, das KochKulturMuseum bei der
        Neugestaltung ihrer Webseite zu unterstützen. Das Projekt läuft noch und
        ich bin stolz auf das bisherige Ergebnis. Die Seite enthält ein
        Shop-System und eine umfangreiche Rezeptsammlung mit Kochanleitungen
        sowie ein Kochlexikon und einen Blog.
        <br />
        <br />
        Das Corporate Design wurde vorgelegt und von mir umgesetzt. Besonders
        wichtig war es, dass die Seite sowohl informativ als auch
        nutzerfreundlich gestaltet wird. Ich freue mich, dass das
        KochKulturMuseum mit der neuen Seite nun besser in der Lage ist, seine
        historische Küche und Events einem breiteren Publikum zu präsentieren.
      </p>
    ),
    tech: ["wordpress", "js"],
  },
  {
    bgColor: "#e50076",
    headline: "Menarini",
    subline: "IPad App",
    text: (
      <p>
        Ich hatte die Chance, in Zusammenarbeit mit einer Agentur mehrere
        Web-Apps für den Kunden Menarini zu erstellen. Menarini ist ein
        führendes Pharmaunternehmen mit Schwerpunkt auf der Forschung,
        Entwicklung und Vermarktung von Arzneimitteln. Die Web-Apps wurden
        speziell für den internen Gebrauch und zur Präsentation von Medikamenten
        auf einem iPad für Ärzte entwickelt, um den Außendienst zu unterstützen.
        <br />
        <br />
        Bei diesem Projekt stand die Dynamik und Animation der Slide-Show im
        Vordergrund, um die Präsentation der Medikamente ansprechend und
        anspruchsvoll zu gestalten. In enger Zusammenarbeit mit der Agentur und
        dem Kunden konnte ich die Bedürfnisse und Anforderungen des Kunden in
        die Umsetzung einbeziehen und die Apps erfolgreich entwickeln.
        <br />
        <br />
        Das Ergebnis waren innovative, benutzerfreundliche und dynamische
        Web-Apps, die die Arbeit des Außendienstes von Menarini, bei der
        Präsentation ihrer Produkte, auf ein neues Level gebracht haben. Ich bin
        stolz darauf, an diesem Projekt beteiligt gewesen zu sein und freue mich
        auf weitere Zusammenarbeit mit Menarini und der Agentur Wundermild.
      </p>
    ),
    tech: ["jqueryMobile", "js"],
    tabletOnly: true,
    video: RanexaVideo,
  },
]

const H2 = styled.h2`
  color: #e2e2e2;
  font-size: 1.5rem;
  @media (min-width: ${Breakpoints.desktop}px) {
    font-size: 3rem;
  }
  width: 100%;
  text-align: center;
  padding: 1rem 1rem 0 1rem;
  text-transform: uppercase;
`

const References = () => {
  return (
    <>
      <Seo title="Referenzen" />
      <h1>Referenzen</h1>
      <motion.div variants={motionItem}></motion.div>
      {references.map(element => {
        return <Reference {...element} key={uniqid()} />
      })}
      <motion.div variants={motionItem}>
        <H2 id="kundenrezensionen">Kundenrezensionen</H2>
      </motion.div>
      <motion.div variants={motionItem}>
        <Testimonials />
      </motion.div>
    </>
  )
}

export default References
