import React from "react";
import styled, { css } from "styled-components";

const BackgroundVideoContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
`;

const Video = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
   ${(props) =>
    props.visible &&
    css`
    display: block;
    background: ${props.visible};
    `}
`;

const BackgroundVideo = (props) => {
  return (
    <BackgroundVideoContainer>
      <Video loop autoPlay>
        <source src={props.src} type="video/mp4" />
        Your browser does not support the video tag.
      </Video>
      <Overlay visible={props.overlay} />
    </BackgroundVideoContainer>
  );
};

export default BackgroundVideo;
