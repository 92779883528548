import React from "react"

import { StaticImage } from "gatsby-plugin-image"
import styled, { css, keyframes } from "styled-components"
import TechStack from "./techstack"
import { useInView } from "react-intersection-observer"
import Breakpoints from "../utils/breakpoints"
import BackgroundVideo from "./backgroundVideo"

const blur = () => keyframes`
from{
  filter: blur(20px);
}
to{
  filter: none;
}
`

const Text = styled.div`
  flex: min-content;
  min-width: 100%;
  max-width: 100%;
  color: #fff;
  padding: 1rem;
  @media (min-width: ${+Breakpoints.tablet + 1}px) {
    min-width: unset;
    padding: 3rem;
  }
  background: ${props => props.bg};
  h2 {
    font-size: 1.3rem;
    font-weight: 400;
  }
  p {
    margin-top: 1rem;
    line-height: 1.3rem;
  }
`

const Subline = styled.div`
  margin-top: 0.5rem;
  font-weight: 700;
  display: block;
`

const MockupContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2rem;
  padding: 0;
  @media (min-width: ${+Breakpoints.tablet + 1}px) {
    flex-direction: row;
    padding: 3rem;
  }
  background: linear-gradient(
    0deg,
    ${props => props.bg} 0%,
    rgb(255, 255, 255) 70%,
    rgb(255, 255, 255) 100%
  );
  background-repeat: no-repeat;
  border: 6px ${props => props.bg} solid;
  gap: 2rem;
`
const Mockup = styled.div`
  position: relative;
  width: 100%;
  max-width: 300px;
  margin: 2rem auto;
  @media (min-width: ${+Breakpoints.tablet + 1}px) {
    margin: 0 auto;
  }
`
const MockupBG = styled.div`
  position: absolute;
  background: rgba(255, 0, 0, 0.6);
  z-index: 1;
  animation: ${blur} 1s;
  ${props =>
    props.type === "desktop" &&
    css`
      width: 79%;
      height: 39%;
      left: 10.5%;
      top: 2%;
      background: url(${props => props.src});
      background-size: cover;
      background-position: top center;
    `}
  ${props =>
    props.type === "tablet" &&
    css`
      width: 48.5%;
      height: 50.5%;
      left: 10.5%;
      top: 48.5%;
      background: url(${props => props.src});
      background-size: cover;
      background-position: top center;
    `}
  ${props =>
    props.type === "tabletOnly" &&
    css`
      width: 94.2%;
      height: 94%;
      left: 3%;
      top: 3%;
      background: url(${props => props.src});
      background-size: cover;
      background-position: top center;
    `}
      ${props =>
    props.type === "mobile" &&
    css`
      width: 24%;
      height: 39%;
      left: 66%;
      top: 48.3%;
      border-radius: 5%;
      background: url(${props => props.src});
      background-size: cover;
      background-position: top center;
    `}
`

const Reference = ({
  srcDesktop,
  srcTablet,
  srcMobile,
  bgColor,
  headline,
  subline,
  text,
  tech,
  tabletOnly,
  video,
}) => {
  const { ref, inView } = useInView({
    threshold: 0,
  })
  return (
    <MockupContainer
      id={headline.toLowerCase().replace(/\s/g, "-")}
      ref={ref}
      bg={bgColor}
    >
      <Mockup>
        {tabletOnly ? (
          <StaticImage
            style={{ zIndex: 2 }}
            src={"../images/mockups/mockup_tablet_landscape.png"}
            alt="Tablet Mockup"
          />
        ) : (
          <StaticImage
            style={{ zIndex: 2 }}
            src={"../images/mockups/mockup.png"}
            alt="Desktop, Tablet, Mobile Mockup"
          />
        )}
        {inView && (
          <>
            {tabletOnly ? (
              <MockupBG type="tabletOnly">
                <BackgroundVideo src={video} />
              </MockupBG>
            ) : (
              <>
                <MockupBG type="desktop" src={srcDesktop} />
                <MockupBG type="tablet" src={srcTablet} />
                <MockupBG type="mobile" src={srcMobile} />
              </>
            )}
          </>
        )}
      </Mockup>
      <Text bg={bgColor}>
        <h2>{headline}</h2>
        <Subline>{subline}</Subline>
        {text}
        <TechStack stack={tech} bgColor={bgColor} />
      </Text>
    </MockupContainer>
  )
}

export default Reference
