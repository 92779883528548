import React from "react"
import { Carousel } from "react-responsive-carousel"
import { StaticImage } from "gatsby-plugin-image"
import uniqid from "uniqid"
import styled from "styled-components"

import "react-responsive-carousel/lib/styles/carousel.min.css"
import "../css/testimonials.css"

const testimonialData = [
  {
    image: (
      <StaticImage
        src={"../images/clients/faces/julianberger_kunden_petra-graef.jpg"}
        alt="Petra Graef"
      />
    ),
    name: "Petra Graef",
    company: "Designerdock München",
    text: `Julian ist seit Ende 2019 in unserem DESIGNERDOCK-Pool.
    Im Frühjahr 2020 haben wir ihn an einen Kunden vermittelt, für den er seitdem immer wieder als Freelancer arbeitet.
    Alles läuft wunderbar! So macht Zusammenarbeit Spaß!`,
    color: "#b1c110",
  },
  {
    image: (
      <StaticImage
        src={"../images/clients/faces/julianberger_kunden_fabian-klinger.png"}
        alt="Fabian Klinger"
      />
    ),
    name: "Fabian Klinger",
    company: "Senior Production Manager - proske",
    text: `Wir arbeiten seit einiger Zeit mit Julian zusammen und 
    sind sehr zufrieden mit der Zusammenarbeit. Julian ist ein zuverlässiger und 
    kompetenter Webentwickler, der immer pünktlich liefert und uns mit seinem Know-how unterstützt. 
    Wir schätzen besonders seine Offenheit für unsere Ideen und seine Fähigkeit, technische Lösungen zu finden, 
    die unseren Anforderungen entsprechen. Wir freuen uns auf die weitere Zusammenarbeit mit Julian und 
    können ihn jedem empfehlen, der qualitativ hochwertige Arbeit sucht.`,
    color: "#0bb5cb",
  },
  {
    image: (
      <StaticImage
        src={"../images/clients/faces/julianberger_kunden_till-ayasse.jpg"}
        alt="Till Ayasse"
      />
    ),
    name: "Till Ayasse",
    company: "Künstler aus Wien",
    text: `Ich hatte genaue Vorstellungen wie meine Homepage aussehen soll und Julian hat es geschafft, 
    dass sie noch besser aussieht als ich es mir vorgestellt hatte! 
    Ich bin begeistert von dem Ergebnis!`,
    color: "#131313",
  },
  {
    image: (
      <StaticImage
        src={"../images/clients/faces/julianberger_kunden_roland-grafl-business-support.png"}
        alt="Roland Grafl"
      />
    ),
    name: "Roland Grafl",
    company: "business support",
    text: `Julian arbeitet seit Jahren als Webentwickler für unsere Agentur und 
    wir sind sehr zufrieden mit seiner Arbeit. Seine Kompetenz und seine Zuverlässigkeit sind beeindruckend. 
    Wir schätzen seine Fähigkeit, schnell und effizient zu arbeiten und uns bei der Entwicklung von Websites und 
    Webanwendungen zu unterstützen. Julian ist ein wichtiger Bestandteil unseres Teams und 
    wir empfehlen ihn gerne weiter an andere Unternehmen, die einen erfahrenen und talentierten Webentwickler suchen.`,
    color: "#fe5000",
  },
  {
    image: (
      <StaticImage
        src={"../images/clients/faces/julianberger_kunden_georg-geml.jpg"}
        alt="Georg Geml"
      />
    ),
    name: "Georg Geml",
    company: "KochKulturMuseum",
    text: `Wir haben Julian als Webentwickler engagiert und sind sehr zufrieden mit seiner Arbeit. 
    Er hat uns bei der Entwicklung einer komplexen Webanwendung geholfen und uns geholfen, unsere Ziele zu erreichen. 
    Wir empfehlen ihn auf jeden Fall weiter.`,
    color: "#e72512",
  },
  {
    image: (
      <StaticImage
        src={"../images/clients/faces/julianberger_kunden_natascha-szakusits.png"}
        alt="Natascha Szakusits"
      />
    ),
    name: "Natascha Szakusits",
    company: "Wundermild Agentur",
    text: `Julian unterstützt uns bereits seit vielen Jahren als Freelancer. 
    Er ist stets hilfbereit, freundlich und vorallem sehr geduldig! 
    Die gemeinsame Arbeit macht Spaß und zwar auch dann, wenn die Kunden sehr fordernd sind.`,
    color: "#f08482",
  },
  {
    image: (
      <StaticImage
        src={"../images/clients/faces/julianberger_kunden_viktor-sales.jpg"}
        alt="Viktor Sales"
      />
    ),
    name: "Viktor Sales",
    company: "Galerist, Künstler und Veranstalter",
    text: `Julian hat unsere Website auf ein neues Level gebracht! 
    Er hat uns gezeigt, wie wichtig es ist, eine benutzerfreundliche und 
    ästhetisch ansprechende Website zu haben. Wir haben viele positive Rückmeldungen von unseren Kunden erhalten und 
    sind sehr dankbar für seine Arbeit.`,
    color: "#D31616",
  },
]

const CarouselItem = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.color};
  margin-top: -6%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 60px;
  padding-left: 5%;
  padding-right: 5%;
  border: 0;
  height: 340px;
  color: #fff;
  @media (max-width: 934px) {
    display: flex;
    flex-direction: column;
    margin-top: -9%;
    padding-top: 8%;
    padding-bottom: 12.5%;
    height: 450px;
  }
`
const Testimonials = () => {
  return (
    <Carousel
      showArrows={true}
      infiniteLoop={true}
      showThumbs={false}
      showStatus={false}
      autoPlay={false}
      swipeable={true}
      interval={4000}
    >
      {testimonialData.map(testimonial => {
        return (
          <div key={uniqid()}>
            {testimonial.image}
            <CarouselItem className="myCarousel" color={testimonial.color}>
              <h3>{testimonial.name}</h3>
              <h4>{testimonial.company}</h4>
              <p>{testimonial.text}</p>
            </CarouselItem>
          </div>
        )
      })}
    </Carousel>
  )
}

export default Testimonials
