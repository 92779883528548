import React from "react"
import styled from "styled-components"
import uniqid from 'uniqid';

import WordpressLogo from "../svg/wordpress.svg"
import JsLogo from "../svg/js.svg"
import GatsbyLogo from "../svg/gatsby.svg"
import ReactLogo from "../svg/rlogo.svg"
import ShopifyLogo from "../svg/shopify.svg"
import TsLogo from "../svg/ts.svg"
import JqueryMobileLogo from "../svg/jqueryMobile.svg"
import PhpLogo from "../svg/php.svg"
import WooLogo from "../svg/woocommerce.svg"

const lookup = {
  wordpress: <WordpressLogo />,
  js: <JsLogo />,
  gatsby: <GatsbyLogo />,
  react: <ReactLogo />,
  shopify: <ShopifyLogo />,
  ts: <TsLogo />,
  jqueryMobile: <JqueryMobileLogo />,
  php: <PhpLogo />,
  woo: <WooLogo />,
}

const TechList = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  gap: .5rem;
  li {
    display: flex;
  }
  svg {
    height: 30px;
    path, rect {
      fill: #fff;
    }
    rect ~ path  {
      fill: ${props => props.bgColor};
    }

  }
`
const TechStack = ({ stack, bgColor }) => {
  return (
    <>
      <TechList bgColor={bgColor}>
        {stack.map((logo) => {
          return <li key={uniqid()}>{lookup[logo]}</li>
        })}
      </TechList>
    </>
  )
}

export default TechStack
